// import Swiper bundle with all modules installed
import Swiper from 'swiper/bundle';

// import styles bundle
// import 'swiper/css/bundle';


import lightGallery from 'lightgallery';

// Plugins
import lgThumbnail from 'lightgallery/plugins/thumbnail'
import lgZoom from 'lightgallery/plugins/zoom'
import lgFullscreen from 'lightgallery/plugins/fullscreen'

//**********************************************/
// Swiper Full height
//**********************************************/

var option = {
    // Optional parameters
    loop: true,
    spaceBetween: 0,
    // autoHeight: true,
    autoplay: {
        delay: 4000,
        disableOnInteraction: false,
      },

    // Navigation arrows
    navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
    },

    slidesPerView: 1,
}

const allMatrixSwiper = document.querySelectorAll('.swiper-fullheight');

var i = 0;
for( i=0; i< allMatrixSwiper.length; i++ ) {
  allMatrixSwiper[i].classList.add('swiper-container-' + i);
  var slider = new Swiper('.swiper-container-' + i, option);
}



//**********************************************/
// Swiper text CTA
//**********************************************/

var optionCta= {
    // Optional parameters
    loop: true,
    spaceBetween: 0,
    // autoHeight: true,

    // Navigation arrows
    pagination: {
        el: ".swiper-pagination",
        clickable: true,
        renderBullet: function (index, className) {
          return '<span class="' + className + '">' + (index + 1) + "</span>";
        },
      },

    slidesPerView: 1,
}

const allCtaSwiper = document.querySelectorAll('.swiper-text-cta');

var i = 0;
for( i=0; i< allCtaSwiper.length; i++ ) {
  allCtaSwiper[i].classList.add('swiper-container-' + i);
  var slider = new Swiper('.swiper-container-' + i, optionCta);
}



//**********************************************/
// swiper fullwidth nav
//**********************************************/

var optionFullwidthNav = {
     // Optional parameters
     // loop: true,
     spaceBetween: 20,
     slidesPerView: 6,
     watchSlidesProgress: true,
     navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
      },
}
var sliderFullWidthNav = new Swiper('.swiper-fullwidth-nav', optionFullwidthNav);

let $lgSwiper = document.getElementById('lg-swipper');
var optionFullwidth = {
     // Optional parameters
     loop: true,
     autoHeight: true,
     spaceBetween: 0,
     // Navigation arrows
     navigation: {
         nextEl: '.swiper-button-next',
         prevEl: '.swiper-button-prev',
     },
     slidesPerView: 1,
     thumbs: {
      swiper: sliderFullWidthNav,
    },
    // Init lightGallery ince swiper is initilized
    on: {
        init: function () {
            const lg = lightGallery($lgSwiper, {
                plugins: [lgZoom, lgThumbnail, lgFullscreen],
            });

            // Before closing lightGallery, make sure swiper slide
            // is aligned with the lightGallery active slide
            $lgSwiper.addEventListener('lgBeforeClose', () => {
                console.log('Index swiper > ' + lg.index);
                sliderFullWidth.slideTo(lg.index, 0)
            });
        },
    }

 }
 const sliderFullWidth = new Swiper('.swiper-fullwidth', optionFullwidth);





//**********************************************/
 // swiper promo bar
 //**********************************************/

var mySwiperPromoBar = new Swiper(".swiper-promo-bar", {
     // Optional parameters
     loop: true,
     slidesPerView: "auto",
     speed: 10000,
     freeMode: true,
     freeModeMomentumBounce: true,
     resistanceRatio: 0,
     clickable: true,
     spaceBetween: 100,

     autoplay: {
         delay: 0,
     },
 });
