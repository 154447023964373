
const filtersRange = document.querySelector('.range-template');
if (!filtersRange) return;

function upperPoint(lowerSlider, upperSlider, lowerVal, upperVal){
    lowerVal = parseInt(lowerSlider.value);
    upperVal = parseInt(upperSlider.value);

    if (upperVal < lowerVal + 150000) {
        upperSlider.value = lowerVal + 150000;

        // if (lowerVal == lowerSlider.min) {
        //     upperSlider.value = 4;
        // }
    }
}

function lowerPoint(lowerSlider, upperSlider, lowerVal, upperVal){
    lowerVal = parseInt(lowerSlider.value);
    upperVal = parseInt(upperSlider.value);

    if (lowerVal > upperVal - 150000) {
        lowerSlider.value = upperVal - 150000;

        // if (upperVal == upperSlider.max) {
        //     lowerSlider.value = parseInt(upperSlider.max) - 50000;
        // }
    }
}

filtersRange.addEventListener('change', (e) => {
    const target = e.target;

    var lowerSlider = document.querySelector('#lower'),
       upperSlider = document.querySelector('#upper'),
       lowerVal = parseInt(lowerSlider.value);
       upperVal = parseInt(upperSlider.value);

    if ( target.classList.contains('price-range-min') ) {
        lowerSlider.oninput = lowerPoint(lowerSlider, upperSlider, lowerVal, upperVal);
    }

    if ( target.classList.contains('price-range-max') ) {
        upperSlider.oninput = upperPoint(lowerSlider, upperSlider, lowerVal, upperVal);
    }
});
