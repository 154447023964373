import '../styles/styles.scss';
// import App from './app';

import { createPopper } from '@popperjs/core';
// import IMask from 'imask';
// import { MarkerClusterer } from "@googlemaps/markerclusterer";

// Bootstrap components
// import 'bootstrap/js/dist/alert';
// import 'bootstrap/js/dist/button';
// import 'bootstrap/js/dist/carousel';
import 'bootstrap/js/dist/collapse';
import 'bootstrap/js/dist/dropdown';
import 'bootstrap/js/dist/modal';
import 'bootstrap/js/dist/offcanvas';
// import 'bootstrap/js/dist/popover';
// import 'bootstrap/js/dist/scrollspy';
import 'bootstrap/js/dist/tab';
// import 'bootstrap/js/dist/toast';
// import 'bootstrap/js/dist/tooltip';

import './general';
import './swiper';
import './navigation';
import './form-range';
// import './imask';
// import './map';


// App.init();
