/**********************************
    Toggle mobile submenus
**********************************/

const submenusLinks = document.querySelectorAll(".submenu-link");
function animateSubmenuLinks(){
    submenusLinks.forEach(function(link) {
        link.classList.add('animate-submenu')
    });
}
function removeSubmenuAnimation(){
    submenusLinks.forEach(function(link) {
        link.classList.remove('animate-submenu')
    });
}


const navLinksSingle = document.querySelectorAll(".nav-link-single");
function animateSingleLinks(){
    navLinksSingle.forEach(function(link) {
        link.classList.add('animate-submenu')
    });
}
function removeSingleLinks(){
    navLinksSingle.forEach(function(link) {
        link.classList.remove('animate-submenu')
    });
}


const submenus = document.querySelectorAll(".nav-link-mobile-submenu");
submenus.forEach(function(menu) {
    menu.addEventListener("click", function() {
        event.preventDefault()
        const submenuId = this.dataset.menuid
        document.querySelector('#' + submenuId).classList.add('show');
        this.classList.add('submenu-open')
        document.querySelector('body').classList.add('submenu-open');
        animateSubmenuLinks();
    });
});


const submenusClose = document.querySelectorAll(".btn-submenu-close");
submenusClose.forEach(function(menu) {
    menu.addEventListener("click", function() {
        event.preventDefault()
        const submenuId = this.dataset.menuid
        document.querySelector('#' + submenuId).classList.remove('show');
        // this.classList.add('submenu-open')
        document.querySelector('body').classList.remove('submenu-open');
        removeSubmenuAnimation();
    });
});



/**********************************
    Toggle mobile menu
**********************************/

if (document.querySelector('.menu-hamburger')) {
    document.querySelector('.menu-hamburger').addEventListener('click', () => {

        if(document.querySelector('body').classList.contains('mobile-menu-open')){
            removeSingleLinks();
        }else{
            animateSingleLinks();
        }

        document.querySelector('body').classList.toggle('mobile-menu-open');
    });
}

if (document.querySelector('#mobile-menu-close-toggler')) {
    document.querySelector('#mobile-menu-close-toggler').addEventListener('click', () => {
        document.querySelector('body').classList.toggle('mobile-menu-open');

        const submenusWrapper = document.querySelectorAll(".menu-submenu-wrapper");
        submenusWrapper.forEach(function(menu) {
            menu.classList.remove('show')
            document.querySelector('body').classList.remove('submenu-open');

        });
        removeSingleLinks();
    });



}
